<div class="meal-log">
  <header class="header">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center flex-column flex-sm-row">
        <a href="">
          <div class="header__logo"></div>
        </a>

        <button class="header__btn mt-3 mt-sm-0" (click)="goToLogInPage()">
          {{ 'HOME.MY_SUPERHUMAN' | translate }}
        </button>
      </div>
    </div>
  </header>

  <div class="page page-center" *ngIf="!isInvalidClient">
    <div class="container container-tight">
      <div class="text-white text-center">
        <div class="diet-log__title">{{ 'TEXT.DIET_LOG' | translate }}</div>

        <div class="fs-3">
          <span class="fw-bold">{{ "LBL.NAME" | translate }}:</span>
          {{ client?.firstName }} {{ client?.lastName }}
        </div>
        <div class="fs-3">
          <span class="fw-bold">{{ "LBL.EMAIL" | translate }}:</span>
          {{ client?.email }}
        </div>

        <div class="fst-italic fw-bold my-3">
          {{ 'TEXT.DIET_LOG_INFO' | translate }}
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <form [formGroup]="dietLogForm" novalidate (ngSubmit)="onSave()">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.LAST_MEAL_DATE" | translate }} *</label>
                  <p-calendar formControlName="date"
                              [maxDate]="dateHelper.today"
                              dateFormat="dd/mm/yy"></p-calendar>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.LAST_MEAL_TIME" | translate }} *</label>
                  <p-calendar formControlName="time"
                              [timeOnly]="true"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.DESCRIBE_WHAT_YOU_EAT" | translate }} *</label>
                  <input type="text"
                         class="form-control"
                         name="description"
                         placeholder="{{'TEXT.DESCRIBE_WHAT_YOU_EAT_PLACEHOLDER' | translate}}"
                         formControlName="description"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.SIGNIFICANT_SPIKE_IN_BLOOD" | translate }}</label>
                  <div class="form-selectgroup">
                    <label class="form-selectgroup-item"
                           *ngFor="let option of yesNoOptions">
                      <input type="radio"
                             [value]="option.value"
                             class="form-selectgroup-input"
                             formControlName="option"
                             [checked]="option.value === f.option.value">
                      <span class="form-selectgroup-label">{{ option.title | translate }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-primary w-100 mt-3" [disabled]="dietLogForm.invalid">
                  {{ "BTN.SUBMIT" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="page page-center">
    <div class="container container-tight py-4">
      <app-not-found *ngIf="isInvalidClient"></app-not-found>
    </div>
  </div>
</div>
