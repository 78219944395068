<input type="file" id="file" (change)="addFile($event)" hidden [accept]="isPdfUpload ? '.pdf' : '*'">
<label class="btn btn-outline-primary w-100" for="file">
  <ng-container *ngIf="uploadedFile">
    {{uploadedFile.originalname}}
  </ng-container>

  <ng-container *ngIf="!uploadedFile">
    <i class="ti ti-upload icon"></i>
    {{label || ('BTN.UPLOAD_FILE' | translate)}}
  </ng-container>
</label>
