import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';

@Injectable()
export class RoleHelper {
  constructor(private userService: UserService) {
  }

  isAdmin(): boolean {
    return this.role === 'ADMIN';
  }
  isClient(): boolean {
    return this.role === 'CLIENT';
  }

  get role(): string {
    return this.userService.getRole();
  }
}
