import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-simple-form-dropdown',
  templateUrl: './simple-form-dropdown.component.html',
})
export class SimpleFormDropdownComponent implements OnInit {
  @Input() options: Array<string>;
  @Input() activeOptions: Array<string>;
  @Input() title: string;
  @Input() parentForm: FormGroup;
  @Input() parentControl: string;
  @Input() showAll: boolean;
  @Input() isWarning: boolean;
  @Input() disabled: boolean;

  activeTitle: string;
  isDropDown: boolean;
  search = '';

  ngOnInit(): void {
    this.activeTitle = this.title;
  }

  onToggle(): void {
    this.isDropDown = !this.isDropDown;
    this.search = '';
  }

  trackByFn(index): number {
    return index;
  }

  get f() {
    return this.parentForm.controls;
  }
}
