import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from "@angular/core";
import { UserService } from '../services/user.service';
import { RoleHelper } from "../helpers/role.helper";

export const IsAuthGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  userService = inject(UserService),
  roleHelper = inject(RoleHelper),
  router = inject(Router)
): boolean | UrlTree => {
  if (userService.isSignedIn()) {
    // Redirect based on user role
    if (roleHelper.isAdmin()) {
      router.navigate(['/user/list']);
    } else if (roleHelper.isClient()) {
      router.navigate(['/client/dashboard']);
    }
    return false; // Prevent access to the protected route
  } else {
    // Handle unauthorized access (e.g., redirect to /auth)
    return true;
  }
};
