import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { MessageService } from "../shared/services/message.service";
import { SharedService } from "../shared/services/shared.service";
import { DateHelper } from "../shared/helpers/date.helper";

@Component({
  selector: 'app-external-meal-log-form',
  templateUrl: './external-meal-log-form.component.html',
  styleUrls: ["./external-meal-log-form.component.scss"],
})

export class ExternalMealLogFormComponent implements OnInit {
  dietLogForm: FormGroup;
  yesNoOptions = this.sharedService.yesNoOptions;
  clientCode: string;
  client: { firstName: string, lastName: string, email: string };
  isInvalidClient: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private message: MessageService,
              private translate: TranslateService,
              public sharedService: SharedService,
              public dateHelper: DateHelper) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.clientCode = params['userCode'];

      if (this.clientCode) {
        this.getClientInfo(this.clientCode);
      }
    });

    this.dietLogForm = this.fb.group({
      date: ["", Validators.required],
      time: ["", Validators.required],
      description: ["", Validators.required],
      option: "",
      answers: [],
    });
  }

  getClientInfo(clientCode: string): void {
    this.sharedService.getClientInfo(clientCode).subscribe(
      (res) => {
        this.client = res.results;
      },
      (err) => {
        if (err.error.errorCode === 4) {
          this.isInvalidClient = true;
        }
      });
  }

  onSave(): void {
    this.createDietEntry();
  }

  createDietEntry(): void {
    let data = {
      date: this.sharedService.getDateAndTime(this.f.date.value, this.f.time.value),
      description: this.f.description.value,
      answers: []
    };

    if (this.f.option.value) {
      data.answers.push({
        questionId: 'NUTRITION_DIARY_QUESTION_1',
        selectedChoice: this.f.option.value
      });
    }

    this.sharedService.createExternalDietEntry(this.clientCode, data).subscribe(
      () => {
        const translatedMessage = this.translate.instant('MSG.SUCCESSFULLY_CREATED_DIARY_LOG');

        this.message.success(translatedMessage);
        this.dietLogForm.reset();
      },
      (err) => {
        this.message.error(err);
      }
    );
  }

  goToLogInPage(): void {
    this.router.navigate(["/auth"]);
  }

  get f(): any {
    return this.dietLogForm.controls;
  }
}
