<div class="modal modal-blur d-block">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-title">{{ title || ('MSG.ARE_YOU_SURE' | translate) }}</div>
        <div>{{text}}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link link-secondary me-auto"
                (click)="emitFalse()">{{cancelButton || "BTN.CANCEL"| translate}}</button>
        <button type="button" class="btn btn-{{confirmButtonSuccess ? 'success' : 'danger'}}" (click)="emitTrue()">{{confirmButton || ('TEXT.YES' | translate)}}</button>
      </div>
    </div>
  </div>
</div>
