<div class="row">
  <div class="col-12">
    <div class="input-icon">
      <input type="text" #input class="form-control" placeholder="{{placeHolder || ('TEXT.SEARCH' | translate)}}"
             (keyup)="onSearch(input.value, $event)">
      <span class="input-icon-addon">
          <i class="ti ti-search"></i>
        </span>
    </div>
  </div>
</div>
