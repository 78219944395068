import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Injectable()
export class MessageService {
  constructor(private toast: ToastrService,
              private translate: TranslateService,
              private router: Router) {
  }

  error(error: any): void {
    let errorMessage: string = "Something went wrong";
    if (error && error.error && error.error.errorCode) {
      switch (error.error.errorCode) {
        case 5:
          errorMessage = this.translate.instant('MSG.ACCOUNT_INACTIVE');
          break;
        case 8:
          errorMessage = this.translate.instant('MSG.INCORRECT_CREDENTIALS');
          break;
        case 9:
          errorMessage = this.translate.instant('MSG.INVALID_EMAIL');
          break;
        case 10:
          errorMessage = this.translate.instant('MSG.EMAIL_ALREADY_REGISTERED');
          break;
        case 11:
          errorMessage = this.translate.instant('MSG.INVALID_CREDENTIALS');
          break;
        case 12:
          errorMessage = this.translate.instant('MSG.TOKEN_EXPIRED');
          localStorage.clear();
          this.router.navigate(['/auth']);
          break;
        case 13:
          errorMessage = this.translate.instant('MSG.INVALID_FILE_TYPE');
          break;
        case 14:
          errorMessage = this.translate.instant('MSG.EXCEL_VALIDATION_ERROR');
          break;
        case 15:
          errorMessage = this.translate.instant('MSG.INVALID_FILE_TYPE');
          break;
        case 16:
          errorMessage = this.translate.instant('MSG.CSV_VALIDATION_ERROR');
          break;
        case 17:
          errorMessage = this.translate.instant('MSG.BIOMARKER_REFERENCE_ERROR');
          break;
        case 18:
          errorMessage = this.translate.instant('MSG.RESULT_ALREADY_UPLOADED');
          break;
        case 19:
          errorMessage = this.translate.instant('MSG.BIOMARKER_REFERENCE_NOT_FOUND');
          break;
        case 21:
          errorMessage = this.translate.instant('MSG.TOO_MANY_REQUESTS');
          break;
        default:
          errorMessage = this.translate.instant('MSG.SOMETHING_WENT_WRONG');
      }
    }
    this.toast.warning(errorMessage);
  }

  warning(message: string): void {
    this.toast.warning(message);
  }

  success(message: string): void {
    this.toast.success(message);
  }
}
