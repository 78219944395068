import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})

export class DateFormatPipe implements PipeTransform {
  transform(inputDate: string): string {
    const dateObject = new Date(inputDate);
    
    const month = dateObject.toLocaleString('en-us', { month: 'short' });
    const year = dateObject.getFullYear().toString().slice(2);
    
    return `${month} '${year}`;
  }
}