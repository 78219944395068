export class DocumentFile {
  _id: string;
  bucket: string;
  contentType: string;
  location: string;
  mimetype: string;
  originalname: string;
  size: number;

  constructor(document) {
    this._id = document._id || undefined;
    this.bucket = document.bucket || undefined;
    this.contentType = document.contentType || undefined;
    this.location = document.location || undefined;
    this.mimetype = document.mimetype || undefined;
    this.originalname = document.originalname || undefined;
    this.size = document.size || undefined;
  }
}
