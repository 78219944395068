import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CalendarModule } from "primeng/calendar";
import { TranslateModule } from "@ngx-translate/core";

import { MessageService } from "./services/message.service";
import { SharedService } from "./services/shared.service";
import { UserService } from "./services/user.service";

import { SearchPipe } from "./pipes/search.pipe";
import { DateFormatPipe } from './pipes/date-format.pipe';

import { RoleHelper } from "./helpers/role.helper";
import { DateHelper } from "./helpers/date.helper";

import { ClickElsewhereDirective } from "./directives/click-elsewhere.directive";
import { ShowPasswordDirective } from "./directives/show-password.directive";
import { StopPropagationDirective } from "./directives/stop-propagation.directive";
import { BackButtonDirective } from "./directives/back-button.directive";
import { DisableButtonDuringRequestDirective } from "./directives/disable-button-during-request.directive";

import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { HeaderComponent } from "./components/header/header.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { QuestionModalComponent } from "./components/modal/question/question-modal.component";
import { SuccessModalComponent } from "./components/modal/success/success-modal.component";
import { SearchComponent } from "./components/search/search.component";
import { UploadComponent } from "./components/upload/upload.component";
import { EmailComponent } from "./components/email/email.component";
import { SortComponent } from "./components/sort/sort.component";
import { SimpleFormDropdownComponent } from "./components/dropdown/simple/simple-form-dropdown.component";
import { ObjectFormDropdownComponent } from "./components/dropdown/object/object-form-dropdown.component";
import { HalfDoughnutChartComponent } from "./components/chart/half-doughnut-chart/half-doughnut-chart.component";
import { FooterComponent } from "./components/footer/footer.component";

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, CalendarModule, TranslateModule],
  declarations: [
    SearchPipe,
    DateFormatPipe,
    ClickElsewhereDirective,
    ShowPasswordDirective,
    StopPropagationDirective,
    BackButtonDirective,
    DisableButtonDuringRequestDirective,
    NotFoundComponent,
    HeaderComponent,
    UploadComponent,
    QuestionModalComponent,
    SuccessModalComponent,
    EmptyStateComponent,
    SimpleFormDropdownComponent,
    ObjectFormDropdownComponent,
    SearchComponent,
    SearchComponent,
    EmptyStateComponent,
    SearchComponent,
    PaginationComponent,
    EmailComponent,
    SortComponent,
    HalfDoughnutChartComponent,
    FooterComponent
  ],
  exports: [
    SearchPipe,
    DateFormatPipe,
    ClickElsewhereDirective,
    ShowPasswordDirective,
    StopPropagationDirective,
    BackButtonDirective,
    DisableButtonDuringRequestDirective,
    HeaderComponent,
    UploadComponent,
    QuestionModalComponent,
    SuccessModalComponent,
    EmptyStateComponent,
    SimpleFormDropdownComponent,
    ObjectFormDropdownComponent,
    SearchComponent,
    SearchComponent,
    EmptyStateComponent,
    SearchComponent,
    PaginationComponent,
    EmailComponent,
    SortComponent,
    HalfDoughnutChartComponent,
    FooterComponent,
    NotFoundComponent
  ],
  providers: [UserService, MessageService, RoleHelper, DateHelper],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [SharedService],
    };
  }
}
