import { Directive, ElementRef } from '@angular/core';
import { BusyService } from '../services/busy.service';

@Directive({
  selector: '[disableDuringRequest]'
})

export class DisableButtonDuringRequestDirective {
  constructor(private busyService: BusyService, private el: ElementRef) {
    this.busyService.onBusyStatusChanged.subscribe(isBusy => {
      if (isBusy) {
        this.el.nativeElement.classList.add('pe-none', ' bg-gray');
      } else {
        this.el.nativeElement.classList.remove('pe-none', ' bg-gray');
      }
    })
  }
}
