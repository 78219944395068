<div class="dropdown dropdown-custom"
     [class.form-group__control--disabled]="disabled"
     ngxClickElsewhere (clickElsewhere)="isDropDown = false">
  <div class="dropdown-custom__btn"
       [class.dropdown-custom__btn--warning]="isWarning"
       [class.dropdown-custom__btn--selected]="f[parentControl]?.value"
       (click)="onToggle()">
    <div class="mr-5">{{ f[parentControl]?.value || activeTitle || 'Choose'}}</div>
    <div class="dropdown-custom__arrow" [class.dropdown-custom__arrow--active]="isDropDown">
      <i class="ti ti-chevron-down"></i>
    </div>
  </div>
  <div class="dropdown-custom__list" [class.dropdown-custom__list--visible]="isDropDown" [formGroup]="parentForm">
    <label class="dropdown-custom__item"
           *ngIf="showAll"
           (change)="isDropDown = false">
      <input class="selectgroup__input" type="radio" [formControlName]="parentControl" [value]="null">
      {{"LBL.SHOW_ALL" | translate}}
    </label>

    <label class="dropdown-custom__item" *ngFor="let option of options; trackBy: trackByFn"
           (change)="isDropDown = false">
      <input class="selectgroup__input" type="radio" [formControlName]="parentControl" [value]="option">
      {{option}}
    </label>
  </div>
</div>

