import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ngxShowPassword]'
})

export class ShowPasswordDirective {
  isShown: boolean;

  constructor(private el: ElementRef) {
    this.initSetup();
  }

  toggleIcon(icon: HTMLElement): void {
    this.isShown = !this.isShown;

    this.el.nativeElement.setAttribute('type', this.isShown ? 'text' : 'password');
    icon.className = this.isShown ? 'ti ti-eye cursor-pointer' : 'ti ti-eye-closed cursor-pointer';
  }

  initSetup(): void {
    const input = this.el.nativeElement.parentNode;
    const iconWrapper = document.createElement('span');
    iconWrapper.className = 'input-group-text';
    const icon = document.createElement('i');
    icon.className = 'ti ti-eye-closed pointer';

    icon.addEventListener('click', event => {
      this.toggleIcon(icon);
    });

    iconWrapper.appendChild(icon);
    input.appendChild(iconWrapper);
  }
}
