import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { RoleHelper } from '../../helpers/role.helper';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {

  constructor(private router: Router,
              private userService: UserService,
              private roleHelper: RoleHelper) {
  }

  goBack(): void {
    if (this.userService.isSignedIn()) {
      if (this.roleHelper.isAdmin()) {
        this.router.navigate(['/user/list']);
      } else {
        this.router.navigate(['/client/dashboard']);
      }
    } else {
      this.router.navigate(['/auth']);
    }
  }
}
