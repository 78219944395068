import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})

export class SearchComponent {
  @Input() placeHolder: string;
  @Input() isEnterClicked: boolean;
  @Output() readonly search: EventEmitter<string> = new EventEmitter<string>();
  searchTimeout: any;

  @ViewChild('input', {static: true}) input: ElementRef;

  onSearch(term: string, event: KeyboardEvent): void {
    if (this.isEnterClicked) {
      if (event.key === 'Enter') {
        this.search.emit(term);
      }
    } else {
      if (term.length > 2 || term === '') {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.search.emit(term);
        }, 1000);
      }
    }
  }
}
