import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges {

  @Input() numberOfPages: number;
  @Output() readonly activePage = new EventEmitter();
  currentPage: number;
  pages: any;

  ngOnChanges(): void {
    this.getPagination(1);
  }

  getPagination(current): any {
    return new Promise(res => {
      this.currentPage = current;
      const list = [];
      const pageLimit = 7;
      let upperLimit: any;
      let lowerLimit: any;

      lowerLimit = upperLimit = Math.min(current, this.numberOfPages);

      for (let b = 1; b < pageLimit && b < this.numberOfPages;) {
        if (lowerLimit > 1) {
          lowerLimit--;
          b++;
        }
        if (b < pageLimit && upperLimit < this.numberOfPages) {
          upperLimit++;
          b++;
        }
      }
      for (let i = lowerLimit; i <= upperLimit; i++) {
        list.push(i);
      }
      if (list[list.length - 1] < this.numberOfPages) {
        list.push(this.numberOfPages);
      }
      if (list[0] > 1) {
        list.unshift(1);
      }
      this.pages = [...list];
      res(current - 1);
    });
  }

  onClick(page: number): any {
    this.getPagination(page)
      .then(data => {
        this.activePage.emit(data);
      });
  }

  trackByFunction(index: number): number {
    return index;
  }
}
