import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class UserService {
  apiURL: string = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  /**
   * Check session expiration
   */
  isSessionValid(): any {
    // checking session expiration
    const token = localStorage.getItem('AccessToken') || undefined;

    if (token !== null) {
      const decodedJWT = jwtDecode(token);
      const expirationEpoh = decodedJWT.exp;
      const currentEpoh = new Date().getTime() / 1000;

      return expirationEpoh > currentEpoh;
    }
  }

  /**
   * Is user signed in?
   */
  isSignedIn(): boolean {
    return localStorage.getItem('AccessToken') !== null && this.isSessionValid();
  }

  /**
   * Get logged in user
   */
  getMe(): any {
    return JSON.parse(localStorage.getItem('User'));
  }

  /**
   * Get selected user
   */
  getSelectedUser(): any {
    return JSON.parse(localStorage.getItem('SelectedUser'));
  }

  /**
   * Get users
   */
  getUsers(skip?: number, limit?: number, search?: string, sortBy?: string): Observable<any> {
    let params = new HttpParams()
      .set('skip', skip)
      .set('limit', limit);

    params = params.append('role', 'CLIENT');
    if (search) params = params.append('search', search);
    if (sortBy) params = params.append('sortBy', sortBy);

    return this.http.get(`${this.apiURL}/user`, { params });
  }

  /**
   * Get user details
   */
  getUserDetails(userId: string): Observable<any> {
    return this.http.get(`${this.apiURL}/user/${userId}`);
  }

  /**
   * Get logged in User role
   */
  getRole(): string {
    const user = this.getMe();

    return user.role;
  }

  /**
   * Save AccessToken to localStorage
   */
  saveAccessToken(token: string): void {
    if (token) {
      localStorage.setItem('AccessToken', token);
    }
  }

  /**
   * Get JWT access token
   */
  getAccessToken(): string {
    return localStorage.getItem('AccessToken');
  }


  /**
   * Set current language
   */
  setCurrentLanguage(language: string): void {
    if (language) {
      localStorage.setItem('CurrentLanguage', language);
    }
  }

  /**
   * Get current language
   */
  getCurrentLanguage(): string {
    return localStorage.getItem('CurrentLanguage');
  }

  /**
   * Save User to localStorage
   */
  saveUser(data: any): void {
    if (data) {
      localStorage.setItem('User', JSON.stringify(data));
    }
  }

  /**
   * Save Selected Client or logged in Client to localStorage
   */
  saveSelectedUser(data: any): void {
    if (data) {
      localStorage.setItem('SelectedUser', JSON.stringify(data));
    }
  }

  /**
   * Sign in User
   */
  signIn(data: any): Observable<any> {
    return this.http.post(`${this.apiURL}/user/auth/signin`, data);
  }

  /**
   * Change password
   */
  changePassword(data: any): Observable<any> {
    return this.http.post(`${this.apiURL}/user/auth/change-password`, data);
  }

  /**
   * Request change for forgotten password
   */
  forgotPassword(data: any): Observable<any> {
    return this.http.post(`${this.apiURL}/user/auth/forgot-password`, data);
  }

  /**
   * Edit user profile
   */
  editUser(userId: string, data: any): Observable<any> {
    return this.http.patch(`${this.apiURL}/user/${userId}`, data);
  }

  /**
   * Edit my profile
   */
  editProfile(data: any): Observable<any> {
    return this.http.patch(`${this.apiURL}/user/update-profile`, data);
  }

  /**
   * Create user profile
   */
  createUser(data: any): Observable<any> {
    return this.http.post(`${this.apiURL}/user/create-client`, data);
  }

  /**
   * Invite client
   */
  inviteClient(userId: string): Observable<any> {
    return this.http.patch(`${this.apiURL}/user/${userId}/send-credentials`, {});
  }

  /**
   * Reset forgotten password
   */
  resetPassword(data: any, resetToken: string): Observable<any> {
    return this.http.post(`${this.apiURL}/user/auth/reset-password/${resetToken}`, data);
  }

  /**
   * Change user status
   */
  changeUserStatus(userId: string, status: string): Observable<any> {
    return this.http.patch(`${this.apiURL}/user/${userId}/status`, { status });
  }

  /**
   * Confirm terms and conditions
   */
  confirmTermsAndConditions(): Observable<any> {
    return this.http.patch(`${this.apiURL}/user/terms-conditions/confirm`, {});
  }

  /**
   * Get user profile
   */
  getUserProfile(): Observable<any> {
    return this.http.get(`${this.apiURL}/user/logged-user`);
  }

  /**
   * Download reference values template
   */
  downloadTemplate(): Observable<any> {
    return this.http.get(`${this.apiURL}/biomarker-reference/download-template`, {
      responseType: 'blob',
      observe: 'body'
    });
  }

  /**
   * Download client reference values
   */
  downloadBiomarkerReference(clientId: string): Observable<any> {
    const params = { clientId };
    return this.http.get(`${this.apiURL}/biomarker-reference/client/${clientId}`, {
      responseType: 'blob',
      observe: 'body'
    });
  }

  /**
   * Upload client reference values
   */
  uploadBiomarkerReference(clientId: string, excel: FormData): Observable<any> {
    return this.http.post(`${this.apiURL}/biomarker-reference/client/${clientId}`, excel);
  }

  /**
   * Check if biomarker reference exists
   */
  checkIfBiomarkerReferenceFileExists(clientId: string): Observable<any> {
    return this.http.get(`${this.apiURL}/biomarker-reference/client/${clientId}/exists`);
  }
}
