import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-object-form-dropdown',
  templateUrl: './object-form-dropdown.component.html',
})
export class ObjectFormDropdownComponent implements OnInit {
  @Input() options: Array<any>;
  @Input() title: string;
  @Input() keyValue: string;
  @Input() keyTitle: string;
  @Input() parentForm: FormGroup;
  @Input() parentControl: string;
  @Input() showAll: boolean;
  @Input() isWarning: boolean;
  @Input() disabled: boolean;

  activeTitle: string;
  isDropDown: boolean;

  @Output() readonly changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.activeTitle = this.title;
  }

  getTitle(): string {
    const index = this.options.findIndex(option => option[this.keyValue] === this.f[this.parentControl].value);
    if (index !== -1) {
      return this.translate.instant(this.options[index][this.keyTitle]);
    } else {
      return this.title || this.translate.instant('LBL.CHOOSE');
    }
  }

  onSelect(option: any): void {
    this.changed.emit(option);
  }

  onToggle(): void {
    this.isDropDown = !this.isDropDown;
  }

  trackByFn(index): number {
    return index;
  }

  get f() {
    return this.parentForm.controls;
  }
}
