import { HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BusyService {
  onBusyStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  requests: Array<HttpRequest<any>> = [];

  // Notify observer about whether there are any active requests
  notify(): void {
    this.onBusyStatusChanged.emit(this.requests.length !== 0);
  }

  // Add request to the storage and notify observer
  onStarted(request: HttpRequest<any>): void {
    this.requests.push(request);
    this.notify();
  }

  // Remove request from storage and notify observer
  onFinished(request: HttpRequest<any>): void {
    const index = this.requests.map(item => item.url).indexOf(request.url);

    if (index !== -1) {
      this.requests.splice(index, 1);
    }

    this.notify();
  }
}
