<div class="page page-center">
  <div class="container-tight py-4">
    <div class="empty">
      <div [innerHTML]="'TEXT.EMPTY_MESSAGE' | translate"></div>
      <div class="empty-action">
        <button (click)="goBack()" class="btn btn-primary" type="button">
          <i class="ti ti-arrow-left icon"></i>
          {{"BTN.TAKE_ME_HOME" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
