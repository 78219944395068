import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';
import { BusyService } from './services/busy.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authorization: string;
  private accept = 'application/json';
  private responseType = 'json';

  constructor(private userService: UserService, private busyService: BusyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authorization = `Bearer ${this.userService.getAccessToken()}`;

    if (request.method === 'POST' && request.body instanceof FormData) {
      this.accept = 'multipart/form-data';
    }

    if (request.method !== 'GET') {
      this.busyService.onStarted(request);
    }

    const headers = {
      Authorization: this.authorization,
      Accept: this.accept,
      responseType: this.responseType,
    };

    return next
      .handle(
        request.clone({
          setHeaders: { ...headers },
        })
      )
      .pipe(
        finalize(() => {
          this.busyService.onFinished(request);
        })
      );
  }
}
