<div class="dropdown dropdown-custom"
     ngxClickElsewhere
     (clickElsewhere)="isDropDown = false"
     [ngClass]="{ 'pe-none bg-gray': disabled }">
  <div class="dropdown-custom__btn"
       [class.dropdown-custom__btn--selected]="f[parentControl].value"
       [class.dropdown-custom__btn--warning]="isWarning"
       (click)="onToggle()">
    <div class="mr-5">{{ getTitle() }}</div>
    <div class="dropdown-custom__arrow" [class.dropdown-custom__arrow--active]="isDropDown">
      <i class="ti ti-chevron-down"></i>
    </div>
  </div>
  <div class="dropdown-custom__list"
       [class.dropdown-custom__list--visible]="isDropDown"
       [formGroup]="parentForm">
    <label class="dropdown-custom__item"
           *ngIf="showAll"
           (change)="isDropDown = false">
      <input class="selectgroup__input" type="radio" [formControlName]="parentControl" [value]="''">
      {{"LBL.SHOW_ALL" | translate}}
    </label>

    <label class="dropdown-custom__item"
           [class.disabled]="option.isInvalid"
           *ngFor="let option of options; trackBy: trackByFn"
           (change)="isDropDown = false">
      <input class="selectgroup__input" type="radio"
             (change)="onSelect(option)"
             [formControlName]="parentControl"
             [value]="option[keyValue]">
      {{option[keyTitle] | translate}}
    </label>
  </div>
</div>

