<ul class="pagination mb-0 align-items-center">
  <li class="page-item mx-2 d-flex align-items-center cursor-{{currentPage === 1 ? 'default' : 'pointer'}}"
      [ngClass]="{'pe-none text-muted' : currentPage === 1}"
      (click)="onClick(currentPage - 1)">
    <i class="ti ti-chevron-left"></i>
    {{"TEXT.PREV" | translate}}
  </li>
  <li class="page-item cursor-pointer"
      [ngClass]="{'active': currentPage === page,
                 'last': (pages[pages.length - 1] === page && pages[pages.length - 1] - pages[pages.length - 2] > 1),
                 'first': (pages[0] === page && pages[1] - pages[0] > 1)}"
      *ngFor="let page of pages; trackBy: trackByFunction"
      (click)="onClick(page)">
    <div class="page-link">{{ page }}</div>
  </li>
  <li class="page-item mx-2 d-flex align-items-center cursor-{{pages[pages.length - 1] === currentPage ? 'default' : 'pointer'}}"
      [ngClass]="{'pe-none text-muted disabled' : pages[pages.length - 1] === currentPage}"
      (click)="onClick(currentPage + 1)">
    {{"TEXT.NEXT" | translate}}
    <i class="ti ti-chevron-right"></i>
  </li>
</ul>
