import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { inject } from "@angular/core";

export const IsLoggedGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  userService = inject(UserService),
  router = inject(Router)
): boolean | UrlTree => {
  if (userService.isSignedIn() && userService.getMe()?.isTermsAndConditionsConfirmed) {
    return true;
  } else {
    router.navigate(['/auth']);
    localStorage.clear();
    return false; // Or redirect to a suitable error page
  }
};
