import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { SharedService } from '../../services/shared.service';
import { DocumentFile } from "../../models/document-file.model";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
})

export class UploadComponent {
  @Input() label: string;
  @Input() parentHandleUpload: boolean;
  @Input() isPdfUpload: boolean;
  @Output() readonly inputChange = new EventEmitter();

  formData = new FormData();
  uploadedFile: DocumentFile;

  constructor(private sharedService: SharedService,
              private message: MessageService) {
  }

  addFile(event: any): void {
    if (event.target.files && event.target.files.length) {
      this.formData = new FormData();
      const file = event.target.files[0];

      if (file) {
        this.formData.append('file', file);

        const fileReader = new FileReader();

        fileReader.onloadend = () => {
          if (this.parentHandleUpload) {
            this.inputChange.emit(this.formData);
          } else {
            this.sharedService.uploadFile(this.formData).subscribe(
              res => {
                this.uploadedFile = new DocumentFile(res.results);
                this.inputChange.emit(res.results);
              },
              err => {
                this.message.error(err);
              }
            );
          }
        };

        fileReader.readAsDataURL(file);
        event.target.value = '';
      }
    }
  }
}
