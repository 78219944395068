import { Component, Input, OnInit } from "@angular/core";
import Chart from "chart.js/auto";

import { UserService } from "src/app/shared/services/user.service";

import { Biomarker } from "src/app/shared/models/biomarker.model";
import { User } from "src/app/shared/models/user.model";

@Component({
  selector: "app-half-doughnut-chart",
  templateUrl: "./half-doughnut-chart.component.html",
  styleUrls: ["./half-doughnut-chart.component.scss"],
})
export class HalfDoughnutChartComponent implements OnInit {
  selectedUser: User = this.userService.getSelectedUser();

  @Input() ageImpact: Array<Biomarker>;

  chart: any;
  greyPercent: number = 0;
  greenPercent: number = 0;
  redPercent: number = 0;
  greyColor: string = "#D5D9EB";
  greenColor: string = "#00BDB7";
  redColor: string = "#BF3D3D";
  selectedUserAge: number;
  biologicalAge: number;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.biologicalAge = Number(this.ageImpact.reduce((acc, cur) => acc + cur?.resultsData[0]?.ageImpact, 0).toFixed(1));

    const today = new Date().getFullYear();
    const birthDate = new Date(this.selectedUser.birthDate).getFullYear();

    this.selectedUserAge = today - birthDate;

    this.calculatePercent();
    this.createChart();
  }

  calculatePercent(): void {
    const Percent = (this.biologicalAge / 30) * 100;

    if (this.biologicalAge > 0) {
      this.redPercent = Percent;
    }

    if (this.biologicalAge < 0) {
      this.greenPercent = Percent;
    }

    if (this.biologicalAge === 0) {
      this.greenPercent = 1;
      this.redPercent = 1;
    }
  }

  createChart() {
    // const overlappingSegments = {
    //   id: "overlappingSegment",
    //   afterDatasetsDraw(chart, arg, plugins) {
    //     const { ctx, data } = chart;
    //     const x = chart.getDatasetMeta(0).data[0].x;
    //     const y = chart.getDatasetMeta(0).data[0].y;
    //     const angle = Math.PI / 180;

    //     console.log(chart.getDatasetMeta(0).data[0]);

    //     chart.getDatasetMeta(0).data.forEach((value, index) => {
    //       const innerRadius = chart.getDatasetMeta(0).data[index].innerRadius;
    //       console.log("innerRadius", innerRadius);
    //       const outerRadius = chart.getDatasetMeta(0).data[index].outerRadius;
    //       console.log("outerRadius", outerRadius);
    //       const endAngle = chart.getDatasetMeta(0).data[index].endAngle;
    //       const startAngle = chart.getDatasetMeta(0).data[index].startAngle;
    //       console.log("andAngle", endAngle);
    //       const radius = (outerRadius - innerRadius) / 2;
    //       const xCoor = (innerRadius + radius) * Math.cos(endAngle + Math.PI);
    //       const yCoor = (innerRadius + radius) * Math.sin(endAngle);
    //       const coordinates = [];

    //       const chartColorOne = this.greenPercent ? -0.08 : -0.08;
    //       const chartColorTwo = this.greenPercent ? 0.09 : 0.09;

    //       console.log(chartColorOne, chartColorTwo);

    //       for (let i = -0.08; i <= 0.09; i += 0.01) {
    //         const xCoor = (innerRadius + radius) * Math.cos((this.greenPercent ? startAngle : endAngle) + Math.PI + i);
    //         const yCoor = (innerRadius + radius) * Math.sin((this.greenPercent ? startAngle : endAngle) + i);
    //         coordinates.push({ x: xCoor, y: yCoor });
    //       }

    //       console.log(coordinates);

    //       ctx.save();
    //       ctx.fillStyle = data.datasets[0].backgroundColor[index];
    //       ctx.translate(x, y);
    //       ctx.beginPath();
    //       coordinates.forEach(({ x, y }) => {
    //         ctx.arc(-x, y, radius - 1, 0, angle * 360, false);
    //       });
    //       ctx.fill();
    //       ctx.restore();
    //     });
    //   },
    // };

    this.chart = new Chart("BiologicalAgeChart", {
      type: "doughnut",

      data: {
        datasets: [
          {
            data: [
              this.greenPercent ? 50 + this.greenPercent : 50,
              this.greenPercent,
              this.redPercent ? this.redPercent : 50,
              this.redPercent < 50 && this.redPercent !== 0 ? 50 - this.redPercent : null,
            ],
            backgroundColor: [
              this.greyColor,
              this.greenPercent ? this.greenColor : "transparent",
              this.redPercent ? this.redColor : this.greyColor,
              this.redPercent < 50 && this.redPercent !== 0 ? this.greyColor : "transparent",
            ],
            borderColor: ["white"],
            borderWidth: 2,
            hoverOffset: 4,
            // borderRadius: {
            //   outerStart: 0,
            //   outerEnd: 30,
            //   innerStart: 0,
            //   innerEnd: 30,
            //   // outerStart: this.greenPercent ? 30 : 0,
            //   // outerEnd: this.redPercent ? 30 : 0,
            //   // innerStart: this.greenPercent ? 30 : 0,
            //   // innerEnd: this.redPercent ? 30 : 0,
            // },
            // borderJoinStyle: "round",
          },
        ],
      },
      options: {
        aspectRatio: 3,
        cutout: "80%",
        circumference: 180,
        rotation: 270,
        scales: {
          x: {
            display: false,
            beginAtZero: true,
          },
          y: {
            display: false,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
        hover: { mode: null },
      },
      // plugins: [overlappingSegments],
    });
  }
}
