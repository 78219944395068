import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
})
export class SuccessModalComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() cancelButton: string;
  @Input() confirmButton: string;
  @Output() readonly responseChanged = new EventEmitter();

  emitTrue(): void {
    this.responseChanged.emit(true);
  }

  emitFalse(): void {
    this.responseChanged.emit(false);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.emitFalse();
  }
}
