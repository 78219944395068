import { Injectable } from "@angular/core";

@Injectable()
export class DateHelper {
  today: Date = new Date();

  calculateYearDifference(today: Date, birthDate: Date): number {
    const differenceInDays = Math.round((today.getTime() - birthDate.getTime()) / (1000 * 3600 * 24));

    return differenceInDays / 365;
  }

  /**
   * Calculates minimum and maximum age ranges, and adjusts the biological age based on a given impact factor.
   * It returns an object containing the minimum and maximum age range (with a +/- 15 years buffer),
   * the adjusted biological age considering the impact factor, and the actual calculated age.
   *
   * @param birthDate The birth date of the individual in string format (YYYY-MM-DD).
   * @param calculatedAgeImpact A number representing the impact on the biological age.
   * @returns An object with properties: minAgeRange, maxAgeRange, biologicalAge, and calculatedAge.
   */
  calculateAgeRangesAndBiologicalAge(
    birthDate: string,
    calculatedAgeImpact: number
  ): { minAgeRange: number; maxAgeRange: number; biologicalAge: number; calculatedAge: number } {
    const today = new Date();
    const birthDateObj = new Date(birthDate);

    const minAgeRange = parseFloat(this.calculateYearDifference(today, birthDateObj).toFixed(0)) - 15;
    const maxAgeRange = parseFloat(this.calculateYearDifference(today, birthDateObj).toFixed(0)) + 15;

    const biologicalAge = parseFloat((this.calculateYearDifference(today, birthDateObj) + calculatedAgeImpact).toFixed(1));

    const calculatedAge = parseFloat(this.calculateYearDifference(today, birthDateObj).toFixed(1));

    return { minAgeRange, maxAgeRange, biologicalAge, calculatedAge };
  }

  getDayAtNoon(date: Date): Date {
    // Parse the input date string to create a Date object
    const inputDate = new Date(date);

    // Get the year, month, and day in the local time zone of the input date
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();

    // Create a new Date object at 12:00 UTC on the same local date
    const dateAtNoonUTC = new Date(Date.UTC(year, month, day, 12, 0, 0));

    return dateAtNoonUTC;
  }
}
