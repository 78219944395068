<div class="modal modal-blur d-block">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="btn-close" (click)="emitFalse()"></button>
      <div class="modal-status bg-primary"></div>
      <div class="modal-body text-center py-4">
        <i class="ti ti-circle-check text-primary" style="font-size: 48px"></i>
        <h3>{{title}}</h3>
        <div class="text-secondary">{{text}}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="emitTrue()">{{confirmButton || ('TEXT.YES' | translate)}}</button>
      </div>
    </div>
  </div>
</div>
