import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html'
})

export class SortComponent {
  @Input() field: string;
  @Output() readonly sort: EventEmitter<any> = new EventEmitter<any>();

  onSort(): void {
    const sortPrefix = this.field.charAt(0);

    this.field = sortPrefix === '-' ? this.field.substr(1) : `-${this.field}`;
    this.sort.emit(this.field);
  }
}
