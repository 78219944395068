import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { RoleHelper } from '../../helpers/role.helper';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  user: User;
  selectedUser: User;
  selectedLink: string;
  isMobileMenuOpen: boolean;
  isDevelopmentMode: boolean;

  constructor(public router: Router,
              private userService: UserService,
              public roleHelper: RoleHelper,
              public sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.isDevelopmentMode = this.checkDevelopmentMode();
    this.user = this.userService.getMe();

    this.router.events.subscribe(event => {
      this.onToggleMenu('');
      this.selectedUser = this.userService.getSelectedUser();
    });
  }

  onToggleMenu(link: string): void {
    this.selectedLink = this.selectedLink !== link ? link : '';
  }

  checkDevelopmentMode(): boolean {
    return location.origin === 'http://localhost:4200' || location.origin === 'https://dev.superhuman.rs';
  }

  isClientView(): boolean {
    return this.roleHelper.isClient();
  }

  isAdminClientView(): boolean {
    return this.roleHelper.isAdmin() && this.router.url.includes('client');
  }

  isAdminView(): boolean {
    return this.roleHelper.isAdmin() && !this.router.url.includes('client');
  }

  goToHome(): void {
    if (this.roleHelper.isAdmin()) {
      this.router.navigate(['/user/list']);
    } else {
      this.router.navigate(['/client/dashboard']);
    }
  }

  onSignout(): void {
    localStorage.clear();
  }
}
