<footer class="footer footer-transparent d-print-none">
  <div class="container-xl">
    <div class="row text-center align-items-center flex-row-reverse">
      <div class="col-lg-auto ms-lg-auto">
        <ul class="list-inline list-inline-dots mb-0">
          <li class="list-inline-item">
            <a href="../../../../assets/Terms-And-Condition.pdf"
               target="_blank"
               rel="noopener">
              {{"TEXT.TERMS" | translate}}
            </a>
          </li>
          <li class="list-inline-item">
            <a href="../../../../assets/Privacy-Policy.pdf"
               target="_blank"
               rel="noopener">
              {{"TEXT.PRIVACY" | translate}}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-auto mt-3 mt-lg-0">
        <ul class="list-inline list-inline-dots mb-0">
          <li class="list-inline-item">
            {{"TEXT.COPYRIGHT" | translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
