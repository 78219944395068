import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SharedService {
  apiURL: string = environment.apiURL;

  clientStatuses = [
    { title: "TEXT.PROSPECT", value: "PROSPECT", color: "secondary" },
    { title: "TEXT.AMBASSADOR", value: "AMBASSADOR", color: "azure" },
    { title: "TEXT.LIVE_DISCOVERY", value: "LIVE_DISCOVERY", color: "success" },
    { title: "TEXT.LIVE_THE_JOURNEY", value: "LIVE_THE_JOURNEY", color: "success" },
    { title: "TEXT.COMPLETED_DISCOVERY", value: "COMPLETED_DISCOVERY", color: "yellow" },
    { title: "TEXT.DISCONTINUED_THE_JOURNEY", value: "DISCONTINUED_THE_JOURNEY", color: "danger" },
  ];

  documentCategories = [
    {
      title: "TEXT.PROGRAM_OVERVIEW",
      value: "PROGRAM_OVERVIEW",
      link: "PROGRAM_OVERVIEW",
      type: "superhuman",
      description: "TEXT.PROGRAM_OVERVIEW_DESC"
    },
    {
      title: "TEXT.ACTIVITY_MOTION",
      value: "ACTIVITY_AND_MOTION",
      link: "ACTIVITY_AND_MOTION",
      type: "superhuman",
      description: "TEXT.ACTIVITY_MOTION_DESC"
    },
    {
      title: "TEXT.MIND_LIFESTYLE",
      value: "MIND_AND_LIFESTYLE",
      link: "MIND_AND_LIFESTYLE",
      type: "superhuman",
      description: "TEXT.MIND_LIFESTYLE_DESC"
    },
    {
      title: "TEXT.MEDICATIONS_SUPPLEMENTS",
      value: "MEDICATIONS_AND_SUPPLEMENTS",
      link: "MEDICATIONS_AND_SUPPLEMENTS",
      type: "superhuman",
      description: "TEXT.MEDICATIONS_SUPPLEMENTS_DESC"
    },
    {
      title: "TEXT.OTHER_INTERVENTIONS",
      value: "OTHER_INTERVENTIONS",
      link: "OTHER_INTERVENTIONS",
      type: "superhuman",
      description: "TEXT.OTHER_INTERVENTIONS_DESC"
    },
    {
      title: "TEXT.DISCOVERY_SUMMARY",
      value: "DISCOVERY_SUMMARY",
      link: "DISCOVERY_SUMMARY",
      type: "result",
      description: "TEXT.DISCOVERY_SUMMARY_DESC"
    },
    {
      title: "TEXT.GENOMICS",
      value: "GENOMICS",
      link: "GENOMICS",
      type: "result",
      description: "TEXT.GENOMICS_DESC"
    },
    {
      title: "TEXT.BLOOD_BIOMARKERS",
      value: "BLOOD_BIOMARKERS",
      link: "BLOOD_BIOMARKERS",
      type: "result",
      description: "TEXT.BLOOD_BIOMARKERS_DESC"
    },
    {
      title: "TEXT.MICROBIOME",
      value: "MICROBIOME",
      link: "MICROBIOME",
      type: "result",
      description: "TEXT.MICROBIOME_DESC"
    },
    {
      title: "TEXT.BODY_COMPOSITION",
      value: "BODY_COMPOSITION",
      link: "BODY_COMPOSITION",
      type: "result",
      description: "TEXT.BODY_COMPOSITION_DESC"
    },
    {
      title: "TEXT.CARDIOVASCULAR",
      value: "CARDIOVASCULAR",
      link: "CARDIOVASCULAR",
      type: "result",
      description: "TEXT.CARDIOVASCULAR_DESC"
    },
    {
      title: "TEXT.FITNESS",
      value: "FITNESS",
      link: "FITNESS",
      type: "result",
      description: "TEXT.FITNESS_DESC"
    },
    {
      title: "TEXT.NUTRITION",
      value: "NUTRITION",
      link: "NUTRITION",
      type: "result",
      description: "TEXT.NUTRITION_DESC"
    },
    {
      title: "TEXT.SELF_REPORTED_VIEW",
      value: "SELF_REPORTED_VIEW",
      link: "SELF_REPORTED_VIEW",
      type: "result",
      description: "TEXT.SELF_REPORTED_VIEW_DESC"
    },
    {
      title: "TEXT.OTHER_ASSESSMENTS",
      value: "OTHER_ASSESSMENTS",
      link: "OTHER_ASSESSMENTS",
      type: "result",
      description: "TEXT.OTHER_ASSESSMENTS_DESC"
    },
    {
      title: "TEXT.DIET_LOG",
      value: "MEAL_LOG",
      link: "MEAL_LOG",
      type: "result",
      description: ""
    },
  ];

  discoverySummaryCategories = [
    {
      category: "DISCOVERY_GENERAL_HEALTH_TODAY",
      title: "TEXT.DISCOVERY_GENERAL_HEALTH_TODAY",
      description: "TEXT.DISCOVERY_GENERAL_HEALTH_TODAY_DESC",
      value: null
    },
    {
      category: "DISCOVERY_LONGEVITY_SUPPORT",
      title: "TEXT.DISCOVERY_LONGEVITY_SUPPORT",
      description: "TEXT.DISCOVERY_LONGEVITY_SUPPORT_DESC",
      value: null
    },
    {
      category: "DISCOVERY_GENETICS",
      title: "TEXT.DISCOVERY_GENETICS",
      description: "TEXT.DISCOVERY_GENETICS_DESC",
      value: null
    },
    {
      category: "DISCOVERY_CARDIOVASCULAR_SYSTEM",
      title: "TEXT.DISCOVERY_CARDIOVASCULAR_SYSTEM",
      description: "TEXT.DISCOVERY_CARDIOVASCULAR_SYSTEM_DESC",
      value: null
    },
    {
      category: "DISCOVERY_METABOLIC_HEALTH",
      title: "TEXT.DISCOVERY_METABOLIC_HEALTH",
      description: "TEXT.DISCOVERY_METABOLIC_HEALTH_DESC",
      value: null
    },
    {
      category: "DISCOVERY_MICROBIOME",
      title: "TEXT.DISCOVERY_MICROBIOME",
      description: "TEXT.DISCOVERY_MICROBIOME_DESC",
      value: null
    },
    {
      category: "DISCOVERY_BODY_COMPOSITION",
      title: "TEXT.DISCOVERY_BODY_COMPOSITION",
      description: "TEXT.DISCOVERY_BODY_COMPOSITION_DESC",
      value: null
    },
    {
      category: "DISCOVERY_AEROBIC_PERFORMANCE_AND_STRENGTH",
      title: "TEXT.DISCOVERY_AEROBIC_PERFORMANCE_AND_STRENGTH",
      description: "TEXT.DISCOVERY_AEROBIC_PERFORMANCE_AND_STRENGTH_DESC",
      value: null
    },
    {
      category: "DISCOVERY_STABILITY_AND_MOBILITY",
      title: "TEXT.DISCOVERY_STABILITY_AND_MOBILITY",
      description: "TEXT.DISCOVERY_STABILITY_AND_MOBILITY_DESC",
      value: null
    },
    {
      category: "DISCOVERY_LIFESTYLE",
      title: "TEXT.DISCOVERY_LIFESTYLE",
      description: "TEXT.DISCOVERY_LIFESTYLE_DESC",
      value: null
    },
    {
      category: "DISCOVERY_STRESS_MOTIVATION_AND_SLEEP",
      title: "TEXT.DISCOVERY_STRESS_MOTIVATION_AND_SLEEP",
      description: "TEXT.DISCOVERY_STRESS_MOTIVATION_AND_SLEEP_DESC",
      value: null
    }
  ];

  longevityDescriptions = [
    {
      text: 'TEXT.LONGEVITY_1',
      values: [0, 1, 2],
    },
    {
      text: 'TEXT.LONGEVITY_2',
      values: [3, 4],
    },
    {
      text: 'TEXT.LONGEVITY_3',
      values: [5, 6],
    },
    {
      text: 'TEXT.LONGEVITY_4',
      values: [7, 8],
    },
    {
      text: 'TEXT.LONGEVITY_5',
      values: [9, 10],
    }
  ];

  referenceValuesColors = ["accent-green", "yellow", "warning", "danger"];

  biomarkerResultsSortOptions = [
    { title: "TEXT.OPTIMIZED", value: "zone" },
    { title: "TEXT.UNOPTIMIZED", value: "-zone" },
  ];

  biomarkerStatuses = [
    { name: "TEXT.OPTIMIZED", zone: 1, color: "accent-green" },
    { name: "TEXT.ATTENTION", zone: 2, color: "yellow" },
    { name: "TEXT.BORDERLINE", zone: 3, color: "orange" },
    { name: "TEXT.CRITICAL", zone: 4, color: "red-dark" },
  ];

  parameterStatuses = [
    { label: "TEXT.CRITICAL", value: "RED", color: "red-dark" },
    { label: "TEXT.NEUTRAL", value: "GREY", color: "dark-gray" },
    { label: "TEXT.ATTENTION", value: "YELLOW", color: "yellow" },
    { label: "TEXT.OPTIMAL", value: "GREEN", color: "accent-green" },
  ];

  targetCategories = [
    { title: "TEXT.DNK", value: "DNK", icon: "ti-dna-2" },
    { title: "TEXT.DEXA", value: "DEXA", icon: "ti-dna" },
    { title: "TEXT.CR_PERFORMANCE", value: "CARDIORESPIRATORY_PERFORMANCE", icon: "ti-dna" },
    { title: "TEXT.GUT_MICROBIOMES", value: "GUT_MICROBIOME", icon: "ti-dna-2" },
    { title: "TEXT.MD_PANEL", value: "MD_PANEL", icon: "ti-dna-2" },
    { title: "TEXT.BLOOD", value: "BLOOD", icon: "ti-dna-2" },
  ];

  impactOptions = [
    { title: "TEXT.HIGH_IMPACT", value: "highImpact" },
    { title: "TEXT.LOW_IMPACT", value: "lowImpact" }
  ];

  genders = [
    { title: "TEXT.MALE", value: "M" },
    { title: "TEXT.FEMALE", value: "F" }
  ];

  languages = [
    { title: "TEXT.ENGLISH", value: "en" },
    { title: "TEXT.SERBIAN", value: "sr" }
  ];

  yesNoOptions = [
    { title: "TEXT.YES", value: "YES" },
    { title: "TEXT.NO", value: "NO" }
  ];

  constructor(private http: HttpClient,
              private translate: TranslateService) {
  }

  /**
   * Upload file
   * @param formData
   */
  uploadFile(formData): Observable<any> {
    return this.http.post(`${this.apiURL}/upload`, formData);
  }

  /**
   * Download file
   */
  downloadFile(data: any, title?: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.setAttribute("download", title);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Translate status
   */
  translateClientStatus(status: string, param: string): string {
    const index = this.clientStatuses.findIndex((item) => item.value === status);

    return index !== -1 ? this.clientStatuses[index][param] : "";
  }

  /**
   * Translate status
   */
  translateStatus(status: string): string {
    const index = this.parameterStatuses.findIndex((item) => item.value === status);

    return index !== -1 ? this.parameterStatuses[index].label : "";
  }

  /**
   * Get reference value color
   */
  getReferenceValueColor(zone: number): string {
    return this.referenceValuesColors[zone - 1];
  }

  /**
   * Get parameter status color
   */
  getParameterStatusColor(parameterStatus: string): string {
    return this.parameterStatuses.filter((status) => status.value === parameterStatus).map((s) => s.color)[0];
  }

  /**
   * Get reference value color for graph
   */
  getReferenceValueColorForGraph(zone: number): string {
    return this.biomarkerStatuses.filter((status) => status.zone === zone)[0].color;
  }

  /**
   * Get reference status name
   */
  getBiomarkerStatus(zone: number): string {
    return this.biomarkerStatuses.filter((status) => status.zone === zone)[0].name;
  }

  /**
   * Merge two date fields into one
   */
  getDateAndTime(date: string, time: string): Date {
    let dateObject = new Date(date);
    let timeObject = new Date(time);

    // Extract date components from the dateObject
    let year = dateObject.getFullYear();
    let month = dateObject.getMonth(); // Months are zero-indexed
    let day = dateObject.getDate();

    // Extract time components from the timeObject
    let hours = timeObject.getHours();
    let minutes = timeObject.getMinutes();
    let seconds = timeObject.getSeconds();
    let milliseconds = timeObject.getMilliseconds();

    // Create a new Date object with the combined components
    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  /**
   * Create external diet entry
   */
  createExternalDietEntry(clientCode: string, data: any): Observable<any> {
    return this.http.post(`${this.apiURL}/nutrition-diary/external/${clientCode}`, data);
  }

  /**
   * Get client info
   */
  getClientInfo(clientCode: string): Observable<any> {
    return this.http.get(`${this.apiURL}/nutrition-diary/external/${clientCode}`);
  }
}
