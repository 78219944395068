<div class="mb-3" [formGroup]="parentForm">
  <label class="form-label">{{"LBL.EMAIL" | translate}} {{isRequired ? '*' : ''}}</label>
  <input type="text"
         class="form-control"
         [class.is-invalid]="f['email'].touched && f['email'].errors && (f['email'].errors['required'] || f['email'].errors['email'])"
         formControlName="email"
         name="email"
         placeholder="example@mail.com"
         id="email"
  >
  <div class="invalid-feedback"
       *ngIf="f['email'].touched && f['email'].errors && f['email'].errors['required']">
    {{"MSG.ENTER_EMAIL" | translate}}
  </div>
  <div class="invalid-feedback"
       *ngIf="f['email'].touched && f['email'].errors && f['email'].errors['email']">
    {{"MSG.INVALID_EMAIL" | translate}}
  </div>
</div>
